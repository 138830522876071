// Select dark or light theme by commenting out the one you do not want.
// IMPORTANT: Also adjust the DARK variable in the src/environment/dark file
$font-path: '~@simpl/fonts/dist/fonts/';
@import '~@aws-amplify/ui-angular/theme.css';
@import '~@simpl/fonts/src/styles/siemens-serif';
@import '~@simpl/fonts/src/styles/siemens-sans';


$image-path: '~@simpl/siemens-brand-bootstrap/src/images/';
@import '~@simpl/siemens-brand-bootstrap/src/styles/siemens-brand-bootstrap-dark';
// @import '~@simpl/siemens-brand-bootstrap/src/styles/siemens-brand-bootstrap-light';

@import '~@simpl/siemens-brand-bootstrap/src/styles/root';

// siemens-brand-ng specific styles
@import '~@simpl/siemens-brand-ng/theme';

@import '~@simpl/siemens-brand-ng/ngx-datepicker/ngx-datepicker-dark';
// @import '~@simpl/siemens-brand-ng/ngx-datepicker/ngx-datepicker-light';

// setting up siemens-brand-icons
$font-path: '~@simpl/siemens-brand-icons/dist/fonts';
@import '~@simpl/siemens-brand-icons/dist/style/simpl-siemens-brand-icons';

@import '~@simpl/siemens-brand-bootstrap/src/styles/card';

html {
  overflow-y: hidden;
  font-size: 16px;
}

h3 {
  font-size: 1rem;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 1.25rem;
  padding-left: 0px;
  line-height: 1.2;
}
h2::before {
  content: none !important;
}

main {
  //padding-top: 100px;
  padding-bottom: 0px;
}

.big-modal{
  width:950px !important
}

.joyride-step__body {
  text-align: left;
  padding: 10px 8px;
  font-size: 13px;
}

joyride-step {
  background-color: #23233C;
  .joyride-step__container {
    background-color: #23233C;
    color: white;
  }
}

.joyride-button {
  background-color: #FF2640;
}

#joyride-step-step3 {
  .joyride-step__container{
    .joyride-step__body {
      white-space: pre-line;
    }
  }
}

#joyride-step-step7 {
  .joyride-step__container{
    .joyride-step__title {
      font-size: 17px;
      color: white !important;
    }
  }
}

.joyride-step__container {
  min-width: 216px;
}

.joyride-step__body > p {
  margin-bottom: 5px;
}

joyride-arrow .joyride-arrow__top {
  border-bottom: 11px solid #23233C;
}

joyride-arrow .joyride-arrow__left {
  border-right: 11px solid #23233C;
}

.joyride-step__header {
  color: white;
}

.joyride-step__close > svg > line {
  stroke: white;
}

.joyride-step__footer {
  .joyride-step__counter {
    visibility: hidden;
  }
}

.modal.show .modal-dialog {
  transform: none;
  margin-top: 6%;
}

.siemens-hide {
  color: #FF2640;
}
